
const backendUrl = "https://vtx.team/-";
const tokenPayUrl = "http://127.0.0.1:5000";
const customerServiceUrl = "https://t.me/vtxteam";
const priceModifier = 1;

const config = {
  backendUrl,
  tokenPayUrl,
  customerServiceUrl,
  priceModifier,
};

export default config;
